const firstName = 'firstName';
const elementInput = 'input';
const lastName = 'lastName';
const userIcon = 'fas fa-user';
const address = 'address';
const cityTitle = 'city';
const postalCode = 'postalCode';
const mobileTitle = 'mobile';
const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿĀ-žȘșȚț]+$/u;

export const personalDetailsConfig = {
  title: 'common:personal_details',
  fields: {
    firstName: {
      title: firstName,
      id: 20,
      elementType: elementInput,
      elementDescription: 'common:choose_a_first_name',
      defaultErrorDescription: 'errors:first_name',
      errorDescription: '',
      elementConfig: {
        type: 'text',
        leftIcon: userIcon,
      },
      value: '',
      validation: {
        required: true,
        pattern: namePattern,
        minLength: '3',
        maxLength: '50',
      },
      valid: false,
      touched: false,
    },
    lastName: {
      title: lastName,
      id: 21,
      elementType: elementInput,
      elementDescription: 'common:choose_a_last_name',
      defaultErrorDescription: 'errors:last_name',
      errorDescription: '',
      elementConfig: {
        leftIcon: userIcon,
      },
      value: '',
      validation: {
        required: true,
        pattern: namePattern,
        minLength: '2',
        maxLength: '50',
      },
      valid: false,
      touched: false,
    },
    gender: {
      title: 'gender',
      id: 22,
      elementType: 'multibox',
      elementDescription: 'common:gender',
      defaultErrorDescription: 'errors:gender',
      errorDescription: '',
      items: [
        { description: 'common:male', title: 'male', value: 'M' },
        { description: 'common:female', title: 'female', value: 'F' },
      ],
      elementConfig: {
        leftIcon: 'fas fa-user',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
  },
};

export const addressDetailsConfig = {
  title: 'common:address_details',
  fields: {
    address: {
      title: address,
      id: 25,
      elementConfig: {
        type: 'text',
        leftIcon: 'fas fa-home',
      },
      value: '',
      elementType: elementInput,
      elementDescription: 'fields:house_number_street_name',
      defaultErrorDescription: 'errors:address',
      errorDescription: '',
      validation: {
        required: true,
        minLength: '1',
        maxLength: '75',
      },
      valid: false,
      touched: false,
    },
    city: {
      title: cityTitle,
      id: 26,
      elementConfig: {
        type: 'text',
        leftIcon: 'fas fa-city',
      },
      elementType: elementInput,
      elementDescription: 'fields:city',
      defaultErrorDescription: 'errors:city',
      errorDescription: '',
      value: '',
      validation: {
        required: true,
        pattern: namePattern,
        minLength: '1',
        maxLength: '30',
      },
      valid: false,
      touched: false,
    },
    postalCode: {
      title: postalCode,
      id: 27,
      valid: false,
      touched: false,
      elementType: elementInput,
      elementDescription: 'fields:postal_code',
      defaultErrorDescription: 'errors:post_code',
      errorDescription: '',
      elementConfig: {
        leftIcon: 'fas fa-envelope-open-text',
      },
      value: '',
      validation: {
        required: true,
        pattern: /^[A-Za-z0-9 ]+$/,
        minLength: '1',
        maxLength: '10',
      },
    },
    country: {
      title: 'country',
      id: 28,
      elementType: 'select',
      elementDescription: 'fields:country',
      defaultErrorDescription: 'errors:country',
      errorDescription: '',
      items: [],
      elementConfig: {
        leftIcon: 'fas fa-globe',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      columnClass: 'is-6',
    },
    currency: {
      id: 7,
      title: 'currency',
      elementType: 'select',
      elementDescription: 'common:currency',
      defaultErrorDescription: 'errors:currency',
      errorDescription: '',
      elementConfig: {
        leftIcon: 'fas fa-coins',
      },
      items: [],
      value: '',
      defaultValue: 'EUR',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      columnClass: 'is-6',
      currencyDefaultRules: {
        CA: 'CAD',
        DE: 'EUR',
        DK: 'EUR',
        CY: 'EUR',
        FR: 'EUR',
        HU: 'EUR',
        NL: 'EUR',
        EG: 'EUR',
        US: 'USD',
      },
    },
    mobile: {
      elementConfig: {
        type: 'tel',
        leftIcon: 'fas fa-mobile-alt',
      },
      defaultErrorDescription: 'errors:mobile',
      errorDescription: '',
      value: '',
      validation: {
        required: true,
        pattern: /^(?:00|\+)[1-9][0-9*]+$/,
        minLength: '1',
        maxLength: '20',
        inline: true,
      },
      valid: false,
      touched: false,
      corrected: false,
      title: mobileTitle,
      id: 29,
      elementType: elementInput,
      elementDescription: 'fields:full_phone_number',
    },
  },
};

export const acceptFieldsConfig = {
  title: 'Accept Fields',
  fields: {
    acceptAgeTerms: {
      id: 30,
      title: 'acceptAgeTerms',
      elementType: 'checkbox',
      elementDescription: 'common:to_continue_confirm_terms_and_conditions_link',
      defaultErrorDescription: 'errors:terms_and_conditions_link',
      errorDescription: '',
      elementConfig: {},
      value: true,
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      consentKey: 'TERMS_AND_CONDITIONS',
    },
    acceptPrivacy: {
      elementType: 'checkbox',
      id: 31,
      title: 'acceptPrivacy',
      elementDescription: 'common:to_continue_confirm_privacy_policy_link',
      defaultErrorDescription: 'errors:privacy_policy_link',
      errorDescription: '',
      elementConfig: {},
      value: true,
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      consentKey: 'PRIVACY_POLICY',
    },
    acceptPromotions: {
      id: 32,
      title: 'receiveEmail',
      elementType: 'checkbox',
      elementDescription: 'common:love_to_get_promotions',
      errorDescription: '',
      elementConfig: {},
      value: true,
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      consentKey: 'EMAIL',
    },
    contactPreference: {
      id: 33,
      title: 'contactPreference',
      elementType: 'checkbox',
      elementDescription: 'common:send_me_sms_offers',
      errorDescription: '',
      elementConfig: {},
      value: true,
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      consentKey: 'SMS',
    },
  },
};
