import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GameThumbnail from '../GameThumbnail';
import GameLoadMoreButton from '../GameLoadMoreButton';
import FeaturedGameThumbnails from './FeaturedGameThumbnails';
import useActiveGames from '../../../hooks/games/useActiveGames';
import useQuery from '../../../hooks/useQuery';

const GameThumbnailList = ({ category, maxLimit, loadMoreButton }) => {
  const { t } = useTranslation();
  const { query } = useQuery();
  const searchText = query.get('search');
  const { activeCategory, activeProvider, activeStudio } = useActiveGames();
  
  const {
    id: categoryId,
    games,
    name,
    displayGamesCount,
    maxLimit: categoryMaxLimit,
    count,
    totalCount,
    isProvider,
    loadAllGames,
    nextUrl,
    type,
    display_name,
  } = category;

  const providerType = type === 'studio'
    ? t('common:game_studio')
    : t('common:providers').slice(0, -1).toLowerCase();

  const gameThumbnails = games?.slice(0, maxLimit)
    .map(item => (
      <GameThumbnail
        key={item.id}
        {...item}
      />
    ));

  if (
    !searchText
    && !activeCategory?.id
    && (activeProvider?.id || activeStudio?.id)
    && !category.games?.length
  ) {
    return (
      <SectionWrapper>
        <h2
          className="is-size-4 has-text-weight-bold has-text-primary container has-text-centered"
          dangerouslySetInnerHTML={{
            __html: t('common:no_games_found_with_contact', { providerType }),
          }}
        />
        <FeaturedGameThumbnails />
      </SectionWrapper>
    );
  }
  if (!category.games?.length) {
    return (
      <SectionWrapper>
        <h2 className="is-size-4 has-text-weight-bold has-text-primary has-text-centered container">
          {t('common:no_games_found')}
        </h2>
        <FeaturedGameThumbnails />
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper>
      <h2 className="is-size-4 has-text-weight-bold has-text-primary container has-text-centered">
        {display_name || name}
      </h2>
      <div
        id="game-grid"
        className="m-game-grid columns is-multiline is-mobile parent-grid"
      >
        {gameThumbnails}
      </div>
      {loadMoreButton ? (
        loadMoreButton
      ) : (
        <GameLoadMoreButton
          categoryId={categoryId}
          count={count}
          totalCount={totalCount}
          maxLimit={categoryMaxLimit}
          displayGamesCount={displayGamesCount}
          isProvider={isProvider}
          loadAllGames={loadAllGames}
          nextUrl={nextUrl}
          type={type}
        />
      )}
    </SectionWrapper>
  );
};

GameThumbnailList.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    games: PropTypes.instanceOf(Array),
    name: PropTypes.string,
    loadAllGames: PropTypes.bool,
    displayGamesCount: PropTypes.number,
    moreGamesToDisplay: PropTypes.number,
    count: PropTypes.number,
    totalCount: PropTypes.number,
    isProvider: PropTypes.bool,
    nextUrl: PropTypes.string,
    maxLimit: PropTypes.number,
    type: PropTypes.string,
  }),
  maxLimit: PropTypes.number,
  loadMoreButton: PropTypes.node,
};

GameThumbnailList.defaultProps = {
  category: {
    id: undefined,
    games: undefined,
    name: undefined,
    loadAllGames: undefined,
    displayGamesCount: undefined,
    moreGamesToDisplay: undefined,
    count: undefined,
    totalCount: undefined,
    isProvider: undefined,
    nextUrl: undefined,
    maxLimit: undefined,
    type: undefined,
    display_name: undefined,
  },
  maxLimit: undefined,
  loadMoreButton: undefined,
};

export const SectionWrapper = ({ children }) => (
  <section className="row container is-fluid m-game-grid__container">
    {children}
  </section>
);

SectionWrapper.propTypes = {
  children: React.Component,
};

SectionWrapper.defaultProps = {
  children: undefined,
};

export default GameThumbnailList;
