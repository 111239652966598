/* eslint-disable import/prefer-default-export */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import usePlayer from '../usePlayer';
import { gamesApi } from '../../lib/api/cms/gamesService/Games';
import { setActiveGamesCategory } from '../../redux/actions/gamesActions/gamesAction';

export const GamesCache = new Map();

export const GamesKeys = {
  newGames: 'new-games',
};

export function useNewGames() {
  const { player } = usePlayer();
  const [games, setGames] = React.useState();
  const [state, setState] = React.useState('loading');
  const dispatch = useDispatch();

  useEffect(() => {
    if (games?.length || state === 'error') return;

    const getData = async () => {
      setState('loading');
      let newGames = GamesCache.get(GamesKeys.newGames);

      try {
        dispatch(setActiveGamesCategory({ newGames: true }));

        if (!newGames) {
          const response = await gamesApi.getNewGames();

          if (!response?.data?.items?.length) {
            dispatch(setActiveGamesCategory({ newGames: false }));
            setState('error');
            return;
          }

          newGames = response?.data?.items;

          if (!newGames?.length) {
            setState('error');
            return;
          }
          GamesCache.set(GamesKeys.newGames, newGames);
        }
        setGames(newGames);
        setState('idle');
      } catch (error) {
        setState('error');
      }
    };
    getData();
  }, [player.partyId, games, state, dispatch]);

  return {
    games,
    state,
  };
}
