/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SkeletonNew } from './SkeletonNew';
import GameThumbnailList, { SectionWrapper } from '../GameThumbnailList';
import { providerFilter, searchFilter } from './FilteredGames';
import useQuery from '../../../hooks/useQuery';
import useActiveGames from '../../../hooks/games/useActiveGames';
import { useNewGames } from '../../../hooks/games/useNewGames';

export const NewGames = () => {
  const { query } = useQuery();
  const searchText = query.get('search');
  const { activeProvider } = useActiveGames();
  const [displayGamesCount, setDisplayGamesCount] = React.useState(12);
  const { games: newGames, state } = useNewGames();

  const filteredGames = newGames
    ?.filter(game => searchFilter(game, searchText))
    ?.filter(game => providerFilter(game, activeProvider?.id, 'provider'));


  if(state === 'error') {
    return null
  }

  if (!filteredGames?.length) {
    return (
      <SectionWrapper>
        <h2 className="is-size-4 has-text-weight-bold has-text-primary container has-text-centered">
          New games
        </h2>
        <div
          id="game-grid"
          className="m-game-grid columns is-multiline is-mobile parent-grid skeleton"
        >
          {new Array(6).fill(true).map((_, i) => (
            <SkeletonNew padding="0.2rem" key={i} />
          ))}
        </div>
      </SectionWrapper>
    );
  }

  const filteredCategory = {
    id: 999996,
    slug: 'new-games',
    name: 'New games',
    displayGamesCount,
    display_name: 'New games',
    moreGamesToDisplay: filteredGames?.length - displayGamesCount,
    position: 0,
    showWinnersList: false,
    isMobile: false,
    loadAllGames: displayGamesCount >= filteredGames?.length,
    games: filteredGames?.slice(0, displayGamesCount).map(game => ({
      ...game,
      mega_thumb: false,
      vertical_thumbnail: {},
    })),
    count: displayGamesCount,
    totalCount: filteredGames?.length,
  };

  return (
    <GameThumbnailList
      category={filteredCategory}
      maxLimit={filteredCategory?.displayGamesCount}
      loadMoreButton={(
        <LoadMoreButton
          handleClick={() => {
            setDisplayGamesCount(filteredGames.length);
          }}
          allGamesLoaded={displayGamesCount >= filteredGames?.length}
          loadAllGames
        />
      )}
    />
  );
};

const LoadMoreButton = ({ handleClick, allGamesLoaded, loadAllGames }) => {
  const { t } = useTranslation();

  if (allGamesLoaded) return null;

  return (
    <div className="level m-button-holder">
      <div className="level-item">
        <button
          type="button"
          onClick={() => {
            handleClick();
          }}
          className={`button m-game-grid-button is-size-5 is-outlined is-primary is-centered mt-4`}
        >
          {!loadAllGames ? t('common:more_games') : t('common:all_games')}
        </button>
      </div>
    </div>
  );
};

LoadMoreButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  allGamesLoaded: PropTypes.bool.isRequired,
  loadAllGames: PropTypes.bool.isRequired,
};
